import Dexie, { type Table } from "dexie";
import type { DRDrawing, DRProperty } from "@/repositories/domain.types";
import { Logger } from "@/logger";

type DATABASE_TABLE_NAMES = "drawings" | "properties";

export class DRAppDatabase extends Dexie {
  static dbId = "DRAppDB";
  readonly dbVersion = 5;
  public drawings!: Table<DRDrawing, number>;
  public properties!: Table<DRProperty, string>;

  public constructor() {
    super(DRAppDatabase.dbId);

    const stores: Record<DATABASE_TABLE_NAMES, string> = {
      drawings: "id",
      properties: "id", //Could be unique IMO
    };
    this.version(this.dbVersion).stores(stores);

    this.on("ready", () => {
      Logger.debug("database.ts : Db ready");
    });
    this.on("blocked", () => {
      alert(
        "Un autre onglet est déjà ouvert avec une autre version du site. Sauvegardez les données et fermez avant de rafraichir la page"
      );
    });
    this.on("close", () => {
      Logger.warn("database.ts : db closed");
      alert(
        `Nous avons eu un problème. Cela arrive parfois lorsqu'une page reste ouverte longtemps. Pourriez-vous raffraichir la page ?`
      );
    });
  }

  wipeDatabase(): Promise<void[]> {
    return Promise.all([this.drawings.clear(), this.properties.clear()]);
  }
}

export const db = new DRAppDatabase();
