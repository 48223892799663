<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { debounce } from "lodash";
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
const SaveState = ref<"saved" | "saving" | "changed">("saved");
import { Facade } from "@/open-cloud/Facade";
import { ViewerEvents } from "@/open-cloud/DRViewer";
const { refHook } = useLongPressTooltip();
import { Logger } from "@/logger";
import { saveWithAlert } from "./utils/save";

const props = defineProps<{
  drawingId?: number;
}>();

onMounted(() => {
  Facade.on(ViewerEvents.DrawingEdited, () => onedit());
});

const debouncedSave = debounce(save, 3000);

onUnmounted(() => {
  debouncedSave.cancel();
});

async function save() {
  Logger.info(`SaveBadge.vue : start saving drawing ${props.drawingId}`);
  SaveState.value = "saving";
  await saveWithAlert();
  Logger.info(`SaveBadge.vue : done saving drawing ${props.drawingId}`);
  SaveState.value = "saved";
}

function onedit() {
  SaveState.value = "changed";
  debouncedSave();
}
</script>

<template>
  <span
    ref="refHook"
    class="badge text-bg-info"
    data-bs-content="Lorsque votre dessin est inactif pendant plus de 3 secondes ou lorsque vous le fermez, il est automatiquement sauvegardé en local et son état est mis à jour.
                    <br>La synchronisation avec le serveur se fait à la fermeture du plan et lorsque l'app est connectée à internet."
    data-bs-title="Sauvegarde"
  >
    <span v-if="SaveState === 'saved'" class="d-flex">
      <i class="bi bi-check"></i>
      <span class="d-none d-sm-none d-md-block">Sauvegardé</span>
    </span>
    <span v-else-if="SaveState === 'changed'" class="d-flex">
      <i class="bi bi-x-lg"></i>
      <span class="d-none d-sm-none d-md-block">Changements</span>
    </span>
  </span>
</template>
