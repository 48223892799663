<script setup lang="ts">
import { ref } from "vue";
import APIClient from "@/APIClient";
import router from "@/router";
import {
  EMAIL_RG_PATTERN,
  getErrorMessage,
  PASSWORD_RG_PATTERN,
} from "@/utils/misc.utils";
import LoadingPrimaryButton from "@/components/generic/LoadingPrimaryButton.vue";
import PasswordRequirements from "@/components/welcome/PasswordRequirements.vue";
import { Logger } from "@/logger";

const name = ref("");
const email = ref("");
const password = ref("");
const confirmation = ref("");

const isLoading = ref(false);
const showError = ref("");

async function signup() {
  isLoading.value = true;
  try {
    await APIClient.register(name.value, email.value, password.value);
    Logger.info(`SignUp.vue : user ${email.value} signed up`);
    Logger.identify();
    router.replace({ name: "home" });
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    Logger.error(
      `SignUp.vue : Couldn't sign up: ${JSON.stringify(error.message)}`
    );
    showError.value = getErrorMessage(error, {
      auth: "Avez-vous créé un compte à cet adresse mail ? Si c'est le cas, vérifiez la syntaxe de votre adresse et mot de passe.",
    });
  }
}

function submit() {
  if (password.value === confirmation.value) {
    signup();
  } else {
    showError.value = getErrorMessage(undefined, {
      default: "Les deux mots de passe ne correspondent pas",
    });
  }
}
</script>
<template>
  <form @submit.prevent="submit" id="signup-form">
    <div class="form-floating my-3">
      <input
        v-model="name"
        type="text"
        class="form-control"
        id="name"
        placeholder="Nom et prénom"
        required
      />
      <label for="name2">Nom et prénom</label>
    </div>
    <div class="form-floating my-3">
      <input
        v-model="email"
        type="email"
        class="form-control"
        id="email"
        placeholder="Adresse email"
        :pattern="EMAIL_RG_PATTERN"
        required
      />
      <label for="email">Adresse email</label>
    </div>
    <div class="form-floating mb-3">
      <input
        v-model="password"
        type="password"
        class="form-control"
        id="password"
        placeholder="Mot de passe"
        :pattern="PASSWORD_RG_PATTERN"
        required
      />
      <label for="password">Mot de passe</label>
    </div>
    <div class="form-floating mb-3">
      <input
        class="form-control my-3"
        type="password"
        id="confirmation"
        v-model="confirmation"
        title="Les mots de passe ne correspondent pas."
        placeholder="Confirmer le mot de passe"
        required
      />
      <label for="password">Confirmer le mot de passe</label>
    </div>
    <PasswordRequirements />
    <div class="text-center mb-3">
      <LoadingPrimaryButton :isLoading="isLoading">
        Créer un compte
      </LoadingPrimaryButton>
    </div>
    <div class="form-floating">
      <p>
        En créant votre compte, vous acceptez la
        <a
          href="https://diagnosticrapid.fr/confidentialite.html"
          target="_blank"
          rel="noopener noreferrer"
          >politique de confidentialité</a
        >
        et les
        <a
          href="https://diagnosticrapid.fr/CGU.html"
          target="_blank"
          rel="noopener noreferrer"
          >Conditions Générales d'Utilisation.</a
        >
      </p>
    </div>
  </form>
  <div v-if="showError !== ''" class="alert alert-danger mt-3" role="alert">
    {{ showError }}
  </div>
</template>
