<script setup lang="ts">
withDefaults(
  defineProps<{
    message: string;
    show?: boolean;
  }>(),
  { message: "Are you sure you want to proceed?", show: false }
);
const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "cancel"): void;
}>();

const closeModal = () => {
  emit("cancel");
};

const confirm = () => {
  emit("confirm");
};
</script>

<template>
  <div
    v-if="show"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Annuler
          </button>
          <button type="button" class="btn btn-primary" @click="confirm">
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal {
  z-index: 1050;
}
.modal-dialog {
  background-color: transparent !important;
}
</style>
