import { Logger } from "./logger";
Logger.init();

// Monitor heap size
import { Monitor } from "./utils/monitor";
import { measureHeap } from "./utils/performance";
const monitor = new Monitor(measureHeap);
monitor.start();

import { createApp } from "vue";
import { loadVisualizeJs } from "@inweb/viewer-visualize/src/Viewer/utils";
import { preloadFonts } from "./open-cloud/FontLoader";

import { Syncer } from "./repositories/Syncer";
import App from "./App.vue";
import router from "./router";
import { VISUALIZE_URL } from "@/open-cloud/DRViewer";
import { pinia } from "@/stores/pinia";

/**
 * make sure the service worker is fully activated and ready before lunching the preload
 * @param fn
 */
function loadOnServiceWorkerReady(fn: () => void) {
  return () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(() => {
        setTimeout(() => {
          fn();
        }, 100);
      });
    }
  };
}

/**
 * This function preloads the VisualizeJS library when the app starts.
 * Benefits:
 * - when opening a file for the first time, it's faster;
 * - this allows caching of the library with the service worker
 *   without needing to open a file first;
 */
async function preloadVisualizeJS() {
  Logger.info(`Preloading VisualizeJS lib at : ${VISUALIZE_URL}`);
  void loadVisualizeJs(VISUALIZE_URL, () => {});
}

const app = createApp(App);

const syncer = new Syncer();
app.provide("syncer", syncer);

app.use(loadOnServiceWorkerReady(preloadVisualizeJS));
app.use(loadOnServiceWorkerReady(preloadFonts));

app.use(pinia);

app.use(router);

app.mount("#app");
