import type { DRViewer } from "@/open-cloud/DRViewer";
import { Logger } from "@/logger";

export type ViewProperty = {
  borderVisibility?: boolean;
  borderColor?: [number, number, number, number];
  borderWidth?: number;
  viewport?: [VisualizeJS.VectorOdTvPoint2d, VisualizeJS.VectorOdTvPoint2d];
  models?: VisualizeJS.TvModel[];
};

const DR_VISUAL_STYLE_NAME = "DR2DWireframe";

// Copied from visualize Viewer.ts
const OVERLAY_VIEW_NAME = "$OVERLAY_VIEW_NAME";

export class ViewBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  static setProperty(view: VisualizeJS.View, props: ViewProperty) {
    if (props.borderVisibility)
      view.setBorderVisibility(props.borderVisibility);
    if (props.borderWidth) view.borderWidth = props.borderWidth;
    if (props.viewport) view.setViewport(props.viewport[0], props.viewport[1]);
    if (props.models) this.addModels(view, props.models);
    if (props.borderColor) view.borderColor = props.borderColor;
  }

  static addModels(view: VisualizeJS.View, models: VisualizeJS.TvModel[]) {
    models.map((model) => view.addModel(model));
  }

  static logModels(view: VisualizeJS.View) {
    for (let i = 0; i < view.numModels(); i++) {
      const model = view.modelAt(i);
      Logger.debug(model.getName());
    }
  }

  initLineWeightMode() {
    const viewer = this.visViewer;
    const lib = this.visLib;
    const device = viewer.getActiveDevice();
    const activeView = device.getActiveView();
    device.setOptionBool(lib.DeviceOptions.kBlocksCache, true);
    activeView.setLineWeightMode(
      this.visLib.OdTvGsViewLineWeightMode.kDeviceFixed
    );
    //activeView.setLineWeightScale(0.5);
    Logger.info(
      `ViewBuilder.initLineWeightMode : ${
        activeView.getLineWeightMode().value
      } ${activeView.getLineWeightScale()}`
    );
  }

  getDRVisualStyle(): VisualizeJS.OdTvVisualStyleId {
    try {
      const visualStyleId =
        this.visViewer.findVisualStyle(DR_VISUAL_STYLE_NAME);
      return visualStyleId;
    } catch (e) {
      Logger.info(
        `Viewbuilder.initVisualStyle :  could not find ${DR_VISUAL_STYLE_NAME} visual style`
      );
      const visualStyleId =
        this.visViewer.createVisualStyle(DR_VISUAL_STYLE_NAME);
      return visualStyleId;
    }
  }

  set2DWireFrameVisualStyle() {
    Logger.info(`Viewbuilder.initVisualStyle : set 2DWireframe visual style`);
    const activeView = this.visViewer.activeView;
    const wireframeId = this.visViewer.findVisualStyle("2DWireframe");
    activeView.visualStyle = wireframeId;
  }

  initDRVisualStyle() {
    Logger.info(`Viewbuilder.initVisualStyle : init visual style`);
    const activeView = this.visViewer.activeView;

    activeView.set2dMode(true);
    const viewer = this.visViewer;
    const lib = this.visLib;

    const visualStyleId = this.getDRVisualStyle();
    const wireframeId = viewer.findVisualStyle("2DWireframe");
    const visualStylePtr = visualStyleId.openObject();
    visualStylePtr.copyFrom(wireframeId);
    //Faces
    //visualStylePtr.setOptionInt32(lib.VisualStyleOptions.kFaceModifiers, lib.FaceModifiers.kSpecularFlag.value, lib.VisualStyleOperations.kSet);
    //visualStylePtr.setOptionInt32(lib.VisualStyleOptions.kFaceColorMode, lib.FaceColorMode.kObjectColor.value, lib.VisualStyleOperations.kSet);
    visualStylePtr.setOptionInt32(
      lib.VisualStyleOptions.kFaceLightingModel,
      lib.FaceLightingModel.kPhong.value,
      lib.VisualStyleOperations.kSet
    );
    //Edges
    visualStylePtr.setOptionInt32(
      lib.VisualStyleOptions.kEdgeModel,
      lib.EdgeModel.kFacetEdges.value,
      lib.VisualStyleOperations.kSet
    );
    //visualStylePtr.setOptionInt32(lib.VisualStyleOptions.kEdgeStyles, lib.EdgeStyles.kNoEdgeStyle.value, lib.VisualStyleOperations.kSet);
    //const colorDef = gouraudPtr.getOptionColor(lib.VisualStyleOptions.kEdgeObscuredColor);
    //visualStylePtr.setOptionColor(lib.VisualStyleOptions.kEdgeObscuredColor, colorDef, lib.VisualStyleOperations.kSet);
    //const colorDef1 = gouraudPtr.getOptionColor(lib.VisualStyleOptions.kEdgeColorValue);
    //visualStylePtr.setOptionColor(lib.VisualStyleOptions.kEdgeColorValue, colorDef1, lib.VisualStyleOperations.kSet);
    // Style
    visualStylePtr.setOptionInt32(
      lib.VisualStyleOptions.kDisplayStyles,
      lib.DisplayStyles.kBackgrounds.value,
      lib.VisualStyleOperations.kSet
    );
    visualStylePtr.setOptionBool(
      lib.VisualStyleOptions.kUseDrawOrder,
      false,
      lib.VisualStyleOperations.kSet
    );

    //this.logVisualStyle(visualStyleId);
    //this.logVisualStyle(gouraudId);
    visualStylePtr.delete();
    activeView.visualStyle = visualStyleId; // Set visual style to active view
  }

  initOverlayVisualStyle() {
    Logger.info(`Viewbuilder.initVisualStyle : init overlay visual style`);
    const overlayView = this.visViewer.getViewByName(
      OVERLAY_VIEW_NAME
    ) as VisualizeJS.View;
    const wireframeId = this.visViewer.findVisualStyle("2DWireframe");
    overlayView.visualStyle = wireframeId;
  }

  logVisualStyle(vsId: VisualizeJS.OdTvVisualStyleId) {
    const vsPtr = vsId.openObject();
    const opt = this.visLib.VisualStyleOptions;
    Logger.debug(
      `>>>>>>>>>>>> logging visual style ${vsPtr.getName()}, ${vsPtr.getDescription()}`
    );
    Logger.debug("---------- Face related props");
    Logger.debug(
      "Lightning Model",
      vsPtr.getOptionInt32(opt.kFaceLightingModel)
    );
    Logger.debug(
      "Lightning quality",
      vsPtr.getOptionInt32(opt.kFaceLightingQuality)
    );
    Logger.debug("Color Mode", vsPtr.getOptionInt32(opt.kFaceColorMode));
    Logger.debug("Modifiers", vsPtr.getOptionInt32(opt.kFaceModifiers));
    Logger.debug("Opacity", vsPtr.getOptionDouble(opt.kFaceOpacityAmount));
    Logger.debug("Specular", vsPtr.getOptionDouble(opt.kFaceSpecularAmount));

    Logger.debug("---------- Edge related props");
    Logger.debug("Model", vsPtr.getOptionInt32(opt.kEdgeModel));
    Logger.debug("Style", vsPtr.getOptionInt32(opt.kEdgeStyles));
    Logger.debug(
      "Silhouette width",
      vsPtr.getOptionInt32(opt.kEdgeSilhouetteWidth)
    );
    Logger.debug(
      "Obscured color",
      vsPtr.getOptionColor(opt.kEdgeObscuredColor).getColor(),
      vsPtr.getOptionColor(opt.kEdgeObscuredColor).getTypeEnum()
    );
    Logger.debug(
      "ObscuredLinePattern",
      vsPtr.getOptionInt32(opt.kEdgeObscuredLinePattern)
    );
    Logger.debug("Modifiers", vsPtr.getOptionInt32(opt.kEdgeModifiers));
    Logger.debug("Halo Gap", vsPtr.getOptionInt32(opt.kEdgeHaloGapAmount));
    Logger.debug("Crease Angle", vsPtr.getOptionInt32(opt.kEdgeCreaseAngle));
    Logger.debug("Width overide", vsPtr.getOptionInt32(opt.kEdgeWidthAmount));
    Logger.debug(
      "Intersection Line Pattern",
      vsPtr.getOptionInt32(opt.kEdgeIntersectionLinePattern)
    );
    Logger.debug(
      "Intersection Color",
      vsPtr.getOptionColor(opt.kEdgeIntersectionColor).getColor(),
      vsPtr.getOptionColor(opt.kEdgeColorValue).getTypeEnum()
    );
    Logger.debug("Overhang", vsPtr.getOptionInt32(opt.kEdgeOverhangAmount));
    Logger.debug("Jitter", vsPtr.getOptionInt32(opt.kEdgeJitterAmount));
    Logger.debug("Opacity", vsPtr.getOptionInt32(opt.kEdgeOpacityAmount));

    Logger.debug("---------- Display related props");
    Logger.debug("Style", vsPtr.getOptionInt32(opt.kDisplayStyles));
    Logger.debug("Draw Order", vsPtr.getOptionBool(opt.kUseDrawOrder));
  }

  logStylesName() {
    const viewer = this.visViewer;

    const itr = viewer.getVisualStylesIterator();
    for (; !itr.done(); itr.step()) {
      const style = itr.getVisualStyle();
      const ptr = style.openObject();
      console.log(ptr.getName());
      ptr.delete();
      style.delete();
    }
    itr.delete();
  }

  logOptions() {
    const opt = this.visLib.VisualStyleOptions;
    for (const key in opt) {
      Logger.debug(key, opt[key]);
    }
  }
}
