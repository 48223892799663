<script setup lang="ts">
import { computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const isAdminRoute = computed(() => {
  return route.path === "/admin";
});

onMounted(() => {});

onUnmounted(() => {});
</script>

<template>
  <div class="d-flex justify-content-between p-2 ps-2">
    <h3>ADMINISTRATION</h3>
    <div>
      <RouterLink v-if="isAdminRoute" to="/admin/users/create">
        <button class="btn btn-primary round-button">
          <i class="bi bi-plus" style="font-size: 19px"></i>
        </button>
      </RouterLink>
    </div>
  </div>
  <div class="m-2">
    <RouterView />
  </div>
</template>

<style lang="css" scoped>
.round-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  position: relative;
}
</style>
