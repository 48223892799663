import { Facade } from "@/open-cloud/Facade";
import { Logger } from "@/logger";
export async function saveWithAlert() {
  try {
    await Facade.saveDraft();
  } catch (e) {
    Logger.error(`saveWithToast : error while saving drawing`);
    alert(
      "Il y a eu un problème pendant la sauvegarde de votre dessin. Fermez-le et actualisez la page avant de réouvrir."
    );
  }
}
