<script setup lang="ts">
import { ref } from "vue";
import APIClient from "@/APIClient";
import router from "@/router";
import { useTogglePassword } from "@/components/composable/useTogglePassword";
import { EMAIL_RG_PATTERN, getErrorMessage } from "@/utils/misc.utils";
import LoadingPrimaryButton from "@/components/generic/LoadingPrimaryButton.vue";
import { Logger } from "@/logger";

const email = ref("");
const password = ref("");

const isLoading = ref(false);
const showError = ref("");

const { toggle, refPwdHook, isPwdToggled } = useTogglePassword();

async function login() {
  isLoading.value = true;
  try {
    await APIClient.login(email.value, password.value);
    Logger.identify();
    router.replace({ name: "home" });
    Logger.info(`Login.vue : user ${email.value} logged in`);
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    Logger.error(`Login.vue : Couldn't log in: ${JSON.stringify(error)}`);
    showError.value = getErrorMessage(error, {
      auth: "Avez-vous créé un compte à cet adresse mail ? Si c'est le cas, vérifiez la syntaxe de votre adresse et mot de passe.",
    });
  }
}
</script>

<template>
  <form @submit.prevent="login" id="login-form">
    <div class="form-floating my-3">
      <input
        v-model="email"
        type="email"
        class="form-control"
        id="email"
        placeholder="Adresse email"
        :pattern="EMAIL_RG_PATTERN"
        required
      />
      <label for="email">Adresse email</label>
    </div>
    <div class="input-group mb-3">
      <div class="form-floating">
        <input
          ref="refPwdHook"
          v-model="password"
          type="password"
          class="form-control"
          id="password"
          placeholder="Mot de passe"
          required
        />
        <label for="password">Mot de passe</label>
      </div>
      <span @click="toggle" class="input-group-text">
        <i v-if="isPwdToggled" class="bi bi-eye-slash"></i>
        <i v-else class="bi bi-eye"></i>
      </span>
    </div>

    <router-link :to="{ name: 'forgot' }">
      J'ai oublié mon mot de passe
    </router-link>
    <div class="text-center">
      <LoadingPrimaryButton :isLoading="isLoading">
        Se connecter
      </LoadingPrimaryButton>
    </div>
  </form>
  <div v-if="showError !== ''" class="alert alert-danger mt-3" role="alert">
    {{ showError }}
  </div>
</template>
