import { Logger } from "@/logger";

export async function measureHeap() {
  if (performance.measureUserAgentSpecificMemory) {
    const memoryInfo = await performance.measureUserAgentSpecificMemory();
    Logger.info(`measureHeap : ${memoryInfo.bytes}`);
  } else if (performance.memory) {
    const memoryInfo = performance.memory;
    Logger.info(`measureHeap : `);
    Logger.info(`totalJSHeapSize : ${memoryInfo.totalJSHeapSize}`);
    Logger.info(`usedJSHeapSize : ${memoryInfo.usedJSHeapSize}`);
    Logger.info(`jsHeapSizeLimit : ${memoryInfo.jsHeapSizeLimit}`);
  } else {
    Logger.info(`measureHeap : no measurement in browser`);
  }
}
