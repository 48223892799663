<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import UserElement from "./UserElement.vue";
import type { UserProperties } from "@/APIClient";
import APIClient from "@/APIClient";

const users = ref<UserProperties[]>([]);

const fetchAllUsers = async () => {
  users.value = await APIClient.fetchAllUsers();
};

const toggleUser = async (userId: number) => {
  const user = users.value.find((user) => user.id === userId);
  if (user) {
    await APIClient.toggleUser(userId);
    user.enabled = !user.enabled;
  }
};

const deleteUser = async (userId: number) => {
  const user = users.value.find((user) => user.id === userId);
  if (user) {
    await APIClient.deleteUser(userId);
    users.value = users.value.filter((user) => user.id !== userId);
  }
};

onMounted(async () => {
  fetchAllUsers();
});

onUnmounted(() => {});
</script>

<template>
  <div class="mx-2">
    <table
      class="table table-sm table-striped align-middle border border-4 border-light mx-auto"
    >
      <thead>
        <tr class="table-light">
          <th scope="col">#</th>
          <th scope="col">Nom</th>
          <th scope="col">Email</th>
          <th scope="col">Role</th>
          <th scope="col">Enregistrement</th>
          <th scope="col">Nombre de Plans</th>
          <th scope="col" width="10%">Actions</th>
        </tr>
      </thead>
      <tbody>
        <UserElement
          v-for="(user, index) in users"
          @toggle="toggleUser(user.id)"
          @delete="deleteUser(user.id)"
          :key="user.id"
          :user="user"
          :index="index"
        />
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
table {
}
</style>
