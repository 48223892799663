<script setup lang="ts">
import { Logger } from "@/logger";
import { saveWithAlert } from "@/components/canvas/utils/save";

async function backToHome() {
  Logger.info(`TopMenu.vue : backToHome()`);
  await saveWithAlert();
  window.location.href = "/";
}
</script>
<template>
  <div class="me-1">
    <!-- anchor can be used to mitigate memory leak -->
    <!--router-link :to="{ name: 'home' }">
            <span class="btn btn-secondary">
            <i class="bi bi-chevron-left"></i>
            </span>
        </router-link-->
    <a href="/" @click="backToHome()"
      ><span class="btn btn-secondary"><i class="bi bi-chevron-left" /></span
    ></a>
  </div>
</template>
