import type { DRViewer } from "@/open-cloud/DRViewer";
import Toolbox from "./ODAToolbox";
import { Logger } from "@/logger";

// https://docs.opendesign.com/tv/tv_working_with_attributes_fill_patterns_props.html#dashes
type HatchPatternLineDef = {
  angle?: number; // angle in radian in the trigonometric direction
  offset: [number, number]; // [x,y] offset of lines between them
  basePoint?: [number, number]; // where the first line is drawn
  dashes?: number[]; // negative is space
};

type HatchPatternLines = {
  lines: HatchPatternLineDef[];
};

export type HatchPattern = {
  patternLines: HatchPatternLines;
  transparency?: number;
};

const MIN_OFFSET_IN_PX = 10;

export default class HatchBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  createPatternLineDef(
    data: HatchPatternLineDef
  ): VisualizeJS.OdTvHatchPatternLineDef {
    const line0 = new this.visLib.OdTvHatchPatternLineDef();
    if (data.basePoint) line0.setBasePoint(data.basePoint);
    if (data.angle) line0.setLineAngle(data.angle);
    line0.setPatternOffset(data.offset);
    if (data.offset) line0.setPatternOffset(data.offset);
    if (data.dashes) {
      const dashes = line0.dashes();
      data.dashes.forEach((dash) => dashes.push_back(dash)); // not typed in .d.ts but it does exists on JS proto
      line0.setDashes(dashes);
    }
    return line0;
  }

  createLines(
    patternLineData: HatchPatternLines
  ): VisualizeJS.OdTvHatchPatternLines {
    const patternLines = new this.visLib.OdTvHatchPatternLines();
    for (const line of patternLineData.lines) {
      const lineDef = this.createPatternLineDef(line);
      patternLines.push_back(lineDef);
      lineDef.delete();
    }
    return patternLines;
  }

  createHatchPattern(
    hatchPatternData: HatchPattern
  ): VisualizeJS.OdTvHatchPatternDef {
    const hatchPattern = new this.visLib.OdTvHatchPatternDef();
    const patternLines = this.createLines(hatchPatternData.patternLines);
    hatchPattern.setPatternLines(patternLines); // type error in .d.ts
    if (hatchPatternData.transparency)
      hatchPattern.setPatternTransparency(hatchPatternData.transparency);
    patternLines.delete();
    // empirical value
    hatchPattern.setDeviation(0.1);
    return hatchPattern;
  }
  // check is the pattern has lines. VisualizeJS.OdTvHatchPatternDef does have isEmpty method
  static isEmptyPattern(pattern: VisualizeJS.OdTvHatchPatternDef): boolean {
    return pattern.patternLines().size() === 0; // it does exist on js object
  }

  setScale(pattern: VisualizeJS.OdTvHatchPatternDef, scale: number) {
    scale = this.computeDisplayableScale(pattern, scale);
    pattern.setPatternScale(scale);
  }
  // if offset of the pattern is too low in px, render crashes
  // so we replace it by a minimum
  computeDisplayableScale(
    pattern: VisualizeJS.OdTvHatchPatternDef,
    scale: number
  ): number {
    const minOffsetNorm = HatchBuilder.computeOffsetsMinNorm(pattern);
    const scaledMinOffsetNorm = minOffsetNorm * scale;
    const minDistOnScreen =
      this.viewer.toolbox.screenDistanceToWorld(MIN_OFFSET_IN_PX);
    if (scaledMinOffsetNorm < minDistOnScreen) {
      const displayableScale = minDistOnScreen / minOffsetNorm;
      Logger.warn(
        `HatchBuilder.computeDisplayableScale : replace scale ${scale} by ${displayableScale} `
      );
      return displayableScale;
    } else {
      return scale;
    }
  }

  static computeOffsetsMinNorm(pattern: VisualizeJS.OdTvHatchPatternDef) {
    let minNorm = 0;
    // not typed in visJS .d.ts
    const lines = pattern.patternLines() as VisualizeJS.OdTvHatchPatternLines;
    const size = lines.size();
    for (let i = 0; i < size; i++) {
      const line = lines.get(i);
      const offset = line.patternOffset();
      const norm = Toolbox.length(offset);
      if (minNorm > norm || minNorm === 0) {
        minNorm = norm;
      }
    }
    return minNorm;
  }
}
