<script setup lang="ts">
import type { UserProperties } from "@/APIClient";
import { onMounted, onUnmounted, ref, computed } from "vue";
import { capitalizeString } from "@/utils/misc.utils";
import ConfirmationModal from "../generic/ConfirmationModal.vue";

const isModalVisible = ref<boolean>(false);
const props = defineProps<{
  user: UserProperties;
  index: number;
}>();
const emit = defineEmits<{
  (e: "toggle"): void;
  (e: "delete"): void;
}>();

const isSuperAdmin = computed(() => props.user.role === "SUPERADMIN");
function toggleUser() {
  emit("toggle");
}

const openModalBeforeDeletion = () => {
  isModalVisible.value = true;
};

const confirmDeletion = () => {
  isModalVisible.value = false;
  emit("delete");
};

const cancelDeletion = () => {
  isModalVisible.value = false;
};

onMounted(() => {});

onUnmounted(() => {});
</script>

<template>
  <tr>
    <th scope="row">{{ "#" + (index + 1) }}</th>
    <td>{{ user.full_name }}</td>
    <td>{{ user.email }}</td>
    <td>{{ capitalizeString(user.role) }}</td>
    <td>{{ user.joined_at }}</td>
    <td>{{ user.drawingsCount }}</td>
    <td>
      <button
        @click.prevent.stop="toggleUser"
        :disabled="isSuperAdmin"
        type="button"
        class="btn"
      >
        <i v-if="user.enabled" class="bi bi-toggle-on"></i>
        <i v-else class="bi bi-toggle-off"></i>
      </button>
      <button
        @click.prevent.stop="openModalBeforeDeletion"
        :disabled="isSuperAdmin"
        type="button"
        class="btn"
      >
        <i class="bi bi-trash-fill"></i>
      </button>
    </td>
  </tr>
  <ConfirmationModal
    :show="isModalVisible"
    @confirm="confirmDeletion"
    @cancel="cancelDeletion"
    message="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
  />
</template>

<style lang="scss" scoped>
$bs-btn-padding-y: 100px;
i {
  font-size: 1.5rem;
}
button {
  --bs-btn-padding-y: 0.5rem; /* Change vertical padding */
  --bs-btn-padding-x: 0.5rem; /* Change horizontal padding */
  border: 0 !important;
  color: var(--bs-blue);
}
</style>
