import { reactive } from "vue";
import { THEME_LIST, type Theme } from "@/components/navbar/Themes";
import type { PhotoMarkerShape } from "@/open-cloud/photoMarker";

const LOCAL_STORAGE_KEY = "settings";

export const LEGEND_LIST_ID = "LegendList";

type Settings = {
  actviveTheme: Theme;
  onlyStylusOn: boolean;
  arrowShape: PhotoMarkerShape; // no circle
  isHatchActive: boolean;
  setActiveTheme: (name: string) => boolean;
  setStylusMode: (onlyStylusOn: boolean) => void;
  setPhotoMarkerShape: (shape: PhotoMarkerShape) => void;
  activateHatch: (active: boolean) => void;
  initialize: () => void;
  persist: () => void;
};

export default reactive<Settings>({
  actviveTheme: THEME_LIST[0],
  onlyStylusOn: true,
  arrowShape: "thin_arrow",
  isHatchActive: true,
  setActiveTheme(name: string) {
    const theme = THEME_LIST.find((theme) => theme.name === name);
    if (theme) {
      this.actviveTheme = theme;
      this.persist();
      return true;
    } else {
      return false;
    }
  },
  setPhotoMarkerShape(shape) {
    this.arrowShape = shape;
    this.persist();
  },
  setStylusMode(onlyStylusOn: boolean) {
    this.onlyStylusOn = onlyStylusOn;
    this.persist();
  },
  activateHatch(active: boolean) {
    this.isHatchActive = active;
    this.persist();
  },
  initialize() {
    const storedSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedSettings) {
      const settings = JSON.parse(storedSettings) as Settings;
      settings.actviveTheme
        ? (this.actviveTheme = settings.actviveTheme)
        : null;
      settings.onlyStylusOn != undefined
        ? (this.onlyStylusOn = settings.onlyStylusOn)
        : null;
      settings.arrowShape ? (this.arrowShape = settings.arrowShape) : null;
      settings.isHatchActive != undefined
        ? (this.isHatchActive = settings.isHatchActive)
        : null;
    }
  },
  persist() {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this));
  },
});
