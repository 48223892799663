<script setup lang="ts">
import { ref } from "vue";
import APIClient from "@/APIClient";
import { EMAIL_RG_PATTERN, getErrorMessage } from "@/utils/misc.utils";
import LoadingPrimaryButton from "../generic/LoadingPrimaryButton.vue";
import { Logger } from "@/logger";

const email = ref("");

const isLoading = ref(false);
const showError = ref("");
const showSuccess = ref("");

async function submit() {
  isLoading.value = true;
  try {
    await APIClient.forgot(email.value);
    isLoading.value = false;
    showError.value = "";
    showSuccess.value =
      "Un email vous a été envoyé. Vérifiez votre boîte de réception.";
    Logger.info(`Forgot.vue : password reinit sent`);
  } catch (error) {
    isLoading.value = false;
    Logger.error(
      `Forgot.vue : Couldn't reset password: ${JSON.stringify(error)}`
    );
    showError.value = getErrorMessage(error);
  }
}
</script>

<template>
  <form @submit.prevent="submit" id="login-form">
    <div class="form-floating my-3">
      <input
        v-model="email"
        type="email"
        class="form-control"
        id="email"
        placeholder="Adresse email"
        :pattern="EMAIL_RG_PATTERN"
        required
      />
      <label for="email">Adresse email</label>
    </div>
    <div class="text-center">
      <LoadingPrimaryButton :isLoading="isLoading">
        Réinitialiser mon mot de passe
      </LoadingPrimaryButton>
    </div>
  </form>
  <div v-if="showError !== ''" class="alert alert-danger mt-3" role="alert">
    {{ showError }}
  </div>
  <div v-if="showSuccess !== ''" class="alert alert-success mt-3" role="alert">
    {{ showSuccess }}
  </div>
</template>
