import type { StoredSettings } from "./CanvasSettingsState";
import type { NoteConfig } from "./UserState";
import { Logger } from "../logger";
import _ from "lodash";
import { Facade } from "../open-cloud/Facade";
import { drawingRepository } from "../repositories/drawing.repository";
import { propertyRepository } from "../repositories/property.repository";
import { reactive, toRaw } from "vue";
import type { Textstyle } from "../open-cloud/builders/TextStyleBuilder";
import { IGNORED_TEXTSTLE_NAMES } from "@/repositories/property.repository";
import NoteMenuState from "./NoteMenuState";
import CanvasSettingsState from "./CanvasSettingsState";

type ForcedSize = "" | number;

export type ScaleMenuState = {
  drawingId: number;
  scales: [NoteConfig[], NoteConfig[]];
  activeScale: null | NoteConfig;
  isTextsizeForced: boolean;
  forcedSize: ForcedSize;
  storedSettings: StoredSettings | null;
  initialize: (
    drawingId: number,
    storedSettings: StoredSettings | null
  ) => void;
  onDrawingOpen: (userScales: NoteConfig[]) => void;
  onLegendChange: (userScales: NoteConfig[]) => void;
  setActiveScale: (scale: NoteConfig) => void;
  getDrawingScales: () => NoteConfig[];
  updateScales: () => void;
  initScalesSizes: () => void;
  forceTextsize: () => void;
  updateOrCreateTextstyle: (textstyle: Textstyle) => void;
};

export default reactive<ScaleMenuState>({
  //First element contains scales in drawing, second scales that are not in drawing and in activeLegend scales
  drawingId: -1,
  /**
   * 0: list of texstyles already inserted in the drawing
   * 1: list of scales defined by user in the legend (minus the ones already in it)
   */
  scales: [[], []],
  activeScale: null,
  isTextsizeForced: false,
  forcedSize: "",
  storedSettings: null,
  initialize(drawingId: number, storedSettings: StoredSettings | null) {
    this.drawingId = drawingId;
    this.storedSettings = storedSettings;
  },
  /**
   * Init the height of textstyles in drawing,
   * update the scales[0] and scales[1]
   * reactivate the previously used scale
   * @param userScales the scale that has been defined in the user legend
   */
  onDrawingOpen(userScales: NoteConfig[]) {
    Logger.info(`CanvasSettingsState.ts : init scale menu`);
    this.initScalesSizes();
    this.scales[1] = userScales; // init scales with all user scales;
    this.updateScales();

    // reactivate the last used scale before drawing was closed

    if (!this.storedSettings || !this.storedSettings.scale) {
      Logger.warn(
        `CanvasSettingsState.ts : can't find stored scale ${JSON.stringify(
          this.storedSettings
        )}`
      );
    }
    // put all the scales in an array
    const flatten = [...this.scales[0], ...this.scales[1]];
    // find the one that was stored as active
    let scale = flatten.find(
      (scale) => scale.name == this.storedSettings?.scale?.name
    );
    if (!scale) {
      Logger.info(
        `CanvasSettingsState.ts : can't find stored scale in drawing scales, setting scale to ${JSON.stringify(
          this.scales[1][0]
        )}`
      );
      if (this.scales[1][0]) {
        scale = this.scales[1][0];
      } else if (this.scales[0][0]) {
        scale = this.scales[0][0];
      }
    }
    this.isTextsizeForced =
      this.storedSettings?.scale?.isTextsizeForced || false;
    this.forcedSize = this.storedSettings?.scale?.forcedSize || "";
    if (scale) this.setActiveScale(scale);
  },
  /**
   * On legend change, need to reset scales, first scale is selected by default.
   */
  onLegendChange(userScales: NoteConfig[]) {
    Logger.info(`CanvasSettingsState.ts :  on legend change`);
    this.scales[1] = userScales; // init scales with all user scales;
    this.updateScales();
    if (this.scales[1][0]) this.setActiveScale(this.scales[1][0]);
  },
  /**
   * Activate the scale and store it to storage, update textstyle to match the scale selected
   * @param scale new scale selected
   */
  setActiveScale(scale: NoteConfig) {
    this.activeScale = scale;
    this.storedSettings = _.set(
      this.storedSettings || {},
      "scale.name",
      scale.name
    ) as StoredSettings;
    if (scale.props.textstyle)
      this.updateOrCreateTextstyle(scale.props.textstyle);
  },
  /**
   * Retrieve the scales that are in the drawing
   */
  getDrawingScales(): NoteConfig[] {
    const drawingStyles = Facade.getTextstyles();
    const scales: NoteConfig[] = [];
    for (const style of drawingStyles) {
      scales.push({
        name: style.name,
        props: {
          textstyle: style,
        },
      });
    }
    return scales;
  },
  updateScales() {
    const drawingScales = this.getDrawingScales();
    const userScales = this.scales[1];
    this.scales[1] = [];
    this.scales[0] = drawingScales;
    for (const userScale of userScales) {
      if (userScale.props.textstyle) {
        const index = this.scales[0].findIndex((scale) => {
          return scale.props.textstyle?.name == userScale.props.textstyle?.name;
        });
        if (index === -1) {
          this.scales[1].push(userScale);
        } else if (
          userScale.props.textstyle.size &&
          this.scales[0][index].props.textstyle?.size &&
          userScale.props.textstyle.size !=
            this.scales[0][index].props.textstyle?.size
        ) {
          Facade.updateOrCreateTextStyle(userScale.props.textstyle);
          this.scales[0][index].props.textstyle = userScale.props.textstyle;
        }
      }
    }
  },
  /**
   * OCS does not keep style property at conversion from DWG to VSFX
   * We need to retrieve them and set them in the VSFX
   * This functions gets original file textstyles from IdB and set them in the drawing
   */
  async initScalesSizes() {
    // FIXME : How To add async method to a reactive state ?
    if (!this.drawingId) {
      Logger.warn(`CanvasSettingState.ts : No drawingId`);
      return;
    }
    const originalId = await drawingRepository.getOriginalFileId(
      this.drawingId
    );
    if (!originalId) {
      Logger.warn(
        `CanvasSettingState.ts : No OriginalFileId for drawing ${this.drawingId}`
      );
      return;
    }
    const data = await propertyRepository.getById(originalId);
    if (!data) {
      Logger.warn(
        `CanvasSettingState.ts : can't find property of originalFileId ${originalId} drawing ${this.drawingId}`
      );
      return;
    }
    for (const textstyle of data.properties.textstyles) {
      if (!IGNORED_TEXTSTLE_NAMES.includes(textstyle.name)) {
        this.updateOrCreateTextstyle(textstyle);
      }
    }
    Logger.info(`CanvasSettingsState.ts : init original drawing scale sizes`);
  },
  forceTextsize() {
    this.storedSettings = _.set(
      this.storedSettings || {},
      "scale.forcedSize",
      this.forcedSize
    );
    this.storedSettings = _.set(
      this.storedSettings || {},
      "scale.isTextsizeForced",
      this.isTextsizeForced
    );
    CanvasSettingsState.updateAndPersist();
    NoteMenuState.updateComputedNoteConfig();
  },
  updateOrCreateTextstyle(textstyle: Textstyle) {
    Facade.updateOrCreateTextStyle(toRaw(textstyle));
  },
});
