<script setup lang="ts">
import { Logger } from "@/logger";
import Settings from "@/stores/Settings";

function activateHatch(active: boolean) {
  Logger.info(`HatchToggler.vue : toggle hatch to ${active}`);
  Settings.activateHatch(active);
}
</script>

<template>
  <div class="btn-group">
    <button
      class="btn"
      :class="Settings.isHatchActive ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => activateHatch(true)"
    >
      Oui
    </button>
    <button
      class="btn"
      :class="!Settings.isHatchActive ? 'btn-primary' : 'btn-outline-primary'"
      @click="() => activateHatch(false)"
    >
      Non
    </button>
  </div>
</template>
